import { addDays, differenceInHours, isAfter, isBefore, set } from "date-fns";
import { DateObject } from "react-multi-date-picker";
import appStore from "store";

export function objectToQueryString(obj, ampersand = "&") {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.append(key, obj[key]);
    }
  }
  return params.toString().replace(/&/g, ampersand);
}
export function getCookie(name) {
  if (typeof document === "undefined" || typeof document.cookie !== "string")
    return null;
  let cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    let [cookieName, cookieValue] = cookies[i].split("=");
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null; // 如果沒找到指定名稱的cookie，返回null
}

export function setDefaultDeliveryZone() {
  appStore.tempSelectedCity = "台北市";
  appStore.tempSelectedZone = "大安區";
  appStore.tempSelectedStation = "chargeZone";
  appStore.tempSelectedZoneId = "56";
  appStore.selectedCity = "台北市";
  appStore.selectedZone = "大安區";
  appStore.selectedStation = "chargeZone";
  appStore.selectedZoneId = "56";
  appStore.tempSelectedReturnCity = "台北市";
  appStore.tempSelectedReturnZone = "大安區";
  appStore.tempSelectedReturnStation = "chargeZone";
  appStore.tempSelectedReturnZoneId = "56";
  appStore.selectedReturnCity = "台北市";
  appStore.selectedReturnZone = "大安區";
  appStore.selectedReturnStation = "chargeZone";
  appStore.selectedReturnZoneId = "56";
}

export function setDefaultSelectedDays() {
  const now = new Date();
  const nextWorkDay = new Date(
    set(addDays(now, 1), {
      hours: 9,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
  );
  const addDaysNum = diffInHours < 12 ? 2 : 1;

  const diffInHours = differenceInHours(nextWorkDay, now);
  const initStartTime = new DateObject(
    now > new Date("2025/01/21") && now < new Date("2025/02/03")
      ? new Date("2025/02/03 09:00:00")
      : set(addDays(now, addDaysNum + 1), {
          hours: 9,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
  );
  const initEndTime = new DateObject(
    now > new Date("2025/01/21") && now < new Date("2025/02/03")
      ? new Date("2025/02/05 09:00:00")
      : set(addDays(now, addDaysNum + 3), {
          hours: 9,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
  );
  appStore.selectedDays = [initStartTime, initEndTime];
  appStore.selectedStartClock = initStartTime.format("HH:mm");
  appStore.selectedEndClock = initEndTime.format("HH:mm");
  appStore.tempSelectedDays = [initStartTime, initEndTime];
  appStore.tempSelectedStartClock = initStartTime.format("HH:mm");
  appStore.tempSelectedEndClock = initEndTime.format("HH:mm");
}

const now = new Date();
const nextWorkDay = new Date(
  set(addDays(now, 1), {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
);

const diffInHours = differenceInHours(nextWorkDay, now);
const addDaysNum = diffInHours < 12 ? 2 : 1;

// 如果現在時間是 2/3 之後，則預設 initStartTime 為 2/15
export const initStartTime = new DateObject(
  now > new Date("2025/01/21") && now < new Date("2025/02/03")
    ? new Date("2025/02/03 09:00:00")
    : set(addDays(now, addDaysNum + 1), {
        hours: 9,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      })
);

export const initEndTime = new DateObject(
  now > new Date("2025/01/21") && now < new Date("2025/02/03")
    ? new Date("2025/02/05 09:00:00")
    : set(addDays(now, addDaysNum + 3), {
        hours: 9,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      })
);

export function getNewStartDays() {
  const now = new Date();
  const isBetweenNewYear =
    isBefore(now, new Date("2025-02-03")) &&
    isAfter(now, new Date("2025-01-23"));
  return isBetweenNewYear ? [] : [initStartTime, initEndTime];
}
